.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .modal-content {
    background-color: #fff;
    margin: 10% auto;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    height: 450px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .sku-image {
    text-align: center;
    margin: 15px 0;
  }
  
  .sku-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .sku-link {
    display: inline-block;
    margin: 10px 0;
    color: #007bff;
    text-decoration: none;
  }
  
  .sku-link:hover {
    text-decoration: underline;
  }
  