  .faq-bot {
    padding: 0px;
    background-color: #fff;
    margin-bottom: 20px; 
  }
  .faq-title {
    font-size: 16px;  /* 设置字体大小 */
    color: #333;   /* 设置颜色 */
    margin-top: 20px; /* 底部间距 */
    margin-bottom: 20px; /* 底部间距 */
  }
  .faq-category-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* 设置问题的颜色和悬停效果 */
  .faq-question {
    font-family: 'Microsoft Yahei', 'Noto Sans CJK KR', '微软雅黑', 'applegothic', 'Sans-serif';
    font-size: 14px;
    color: #FE5F29;
    font-weight: 600;
    display: block;
    line-height: 22px;
  }
  
  .faq-question:hover {
    color: #ff4d4f; /* 鼠标悬停时改变颜色 */
  }
  
  /* Chatbot 回复卡片样式 */
  .chatbot-reply-card {
    margin-top: 20px;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-reply-card .ant-card-body {
    color: #333;
    font-size: 14px;
  }
  
  .chatbot-reply-card .ant-btn {
    margin-left: 10px;
  }
  
  /* 设置Chatbot回复卡片的按钮颜色 */
  .chatbot-reply-card .ant-btn-link {
    color: #ff4d4f;
  }
  
  .chatbot-reply-card .ant-btn-link:hover {
    color: #52c41a;
  }
  /* 人工模式提示样式 */
.human-mode-notice {
    margin-top: 20px;
    color: #FE5F29;
    font-weight: bold;
  }

  .feedback-container {
	position : absolute;
	bottom   : 0px;
	left   	 : 408px;

  }

  .button3
  {
    font-family		: 'Microsoft Yahei', 'Noto Sans CJK KR', '微软雅黑', 'applegothic', 'Sans-serif';
    font-size		: 14px;
    color			: #fff;
    font-weight		: 600;
    height			: 30px;
    padding			: 0 10px;
    background		: #000000;
    border			: none;
      border-radius   	: 50px;
  }
  .button3:hover {
    background-color: #000000; /* 鼠标悬停时背景颜色 */
    color: #fff; /* 鼠标悬停时文本颜色 */
  }
  