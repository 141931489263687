.timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0; /* 上下间距 */
    position: relative;
  }
  
  .timeline hr {
    border: none;
    border-top: 1px solid #ddd; /* 时间线颜色 */
    flex-grow: 1; /* 占据左右剩余空间 */
    margin: 0 10px; /* 线与文字之间的间距 */
  }
  
  .timeline span {
    font-size: 14px;
    color: #888; /* 时间文本颜色 */
    background-color: #fff;
    padding: 0 10px;
    z-index: 1; /* 确保文字在时间线上方 */
  }
 /*  
  .timeline::before,
  .timeline::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 40px);
    height: 1px;
    background-color: #ddd;
    z-index: 0;
  }
   */
  .timeline::before {
    left: 0;
  }
  
  .timeline::after {
    right: 0;
  }
  
  .customized-message-item {
    position: relative; /* 允许绝对定位的子元素 */
  }
