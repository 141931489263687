.sku-list-container {
  position: relative; /* 使头像定位相对于该容器 */
}

.ufr {
  list-style-type: none; /* 去掉默认列表样式 */
  padding: 0; /* 去掉内边距 */
  margin: 0; /* 去掉外边距 */
}

.sku-item {
  display: flex; /* 使用flex布局 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px; /* SKU项之间的间距 */
}

.sku-info {
  display: flex; /* 使用flex布局 */
  align-items: flex-start; /* 顶部对齐 */
}

.attachment {
  margin-right: 10px; /* 图像与文本之间的间距 */
}

.icon1 {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 顶部距离 */
  right: 10px; /* 右侧距离 */
  z-index: 1; /* 确保头像在其他元素之上 */
}
