.custom-message-input {
  background-color: white; /* 整个输入框容器背景色 */
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}
.message-preview {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 10px 0;
}

.media-container, .file-container {
  display: flex;
  align-items: center; /* 确保底部对齐 */
}

.media-preview, .file-icon {
  max-width: 50px; /* 根据需要调整缩略图大小 */
  margin-right: 10px; /* 缩略图和文件名之间的间距 */
}

.file-name {
  font-size: 12px; /* 调整文件名字体大小 */
  color: #333; /* 颜色可以根据需求调整 */
  white-space: nowrap; /* 防止换行 */
  overflow: hidden; /* 溢出隐藏 */
  text-overflow: ellipsis; /* 超出部分用省略号显示 */
}

.custom-message-input .input-wrapper {
  background-color: white;
  display: flex;
  height: 150px;
  gap: 20px;
  justify-content: space-between;
}
.custom-message-input .send-button {
  background-color: #FF4000; /* 发送按钮背景色 */
  color: white; /* 按钮文字颜色 */
  align-self: end;
  margin-bottom: 20px;
  margin-right: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-message-input .send-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.send-button:hover {
  background-color: #FF4000; /* 按钮悬停时的背景色 */
}

@media (max-width: 991px) {
  .input-wrapper {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.input-tools {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.container {
  display: flex; /* 使用 Flexbox 布局 */
  width: 98%;
  align-items: center; /* 垂直居中对齐 */
  justify-content: space-between; /* 使内容在水平方向上均匀分布 */
  padding: 10px; /* 可选，设置容器内边距 */
  background-color: transparent; /* 背景颜色 */
}

.button-group {
  display: flex; /* 使按钮组使用 Flexbox */
  gap: 20px; /* 按钮之间的间距 */
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer; /* 鼠标悬停时显示为手指 */
}

.right-button {
  margin-left: auto; /* 将最后一个按钮推到右侧 */
  margin-right: 0px;
}

.tool-icons {
  display: flex;
  align-items: start;
  gap: 20px;
}

.tool-icon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  align-self: stretch;
}

.input-text {
  color: #262525;
  margin-top: 21px;
  font: 400 14px Noto Sans CJK KR, -apple-system, Roboto, Helvetica, sans-serif;
}

.his-icon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  align-self: start;
}


textarea {
  border: none;
  outline: none;
  background-color: white; /* 输入框背景色 */
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  resize: none;
}



button:hover {
  background-color: #fff;
}


.tool-button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
}
.tool-button:active {
  background: transparent;
  border: none;
  outline: none;
}
.tool-button:focus {
  outline: none;
}
.his-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  margin-right: 10px;
  padding: 5px;
}
.his-button:active {
  background: transparent;
  border: none; 
  outline: none;
}
.his-button:focus {
  outline: none;
}
.emojis{display:flex;justify-content:flex-start;align-items:center;max-width:300px;flex-wrap:wrap;}
.emojis b{border:1px solid #f3f2ef;display:block;width:30px;height:30px;box-sizing:border-box;}
.emojis img{width:100%;height:100%;}

.write_box{
  flex: 1;
  font-size: 14px;
  max-width: 69vw;
  box-sizing: border-box;
  padding: 10px;
  min-height: 120px;
  max-height: 140px;
  overflow-y: auto;
  border: 0;
  outline: none;
  background-color: #fff;  
  color: #333333; /* 输入文本颜色 */
  font-family: "Open Sans",sans-serif;
  font-weight: 400;
  line-height: 24px;
  outline: none; /* 去掉输入框默认聚焦样式 */
}

.input_box_all {
  width: 100%;
  background-color: #eceff1;
  overflow-y: scroll;
}


.write_box:focus {
  border-color: white; /* 聚焦时边框颜色 */
}



