.channel-wrap{
    position: relative;
    max-width: 83.6vw;
    height: 68vh;
  }
.channel-chat{
    width: 100%;
    height: 100%;
    float: right;
  }

  .channel-list,
  .channel-chat{
    float: left;
  }

  .sendbird-conversation__footer {
    width: 100%;
    height: 0;
    padding: 0px 0px 0px 0px;
}
.sendbird-connection-status {
  display: none;
}
