.frame {
    background-color: #f0f0f0;
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 74px 1fr; 
    grid-template-areas:
    "header  header"
    "mainarea servarea";
  }

.header {
  grid-area: header;
  background-color: #fff;
}

.mainarea {
  grid-area: mainarea;
  background-color: #fff;
  height: 68vh;
}

.servarea {
  grid-area: servarea;
  display: flex;
  flex-direction: column;
  gap: 0px;
  background-color: #fff;
  height: 68vh
}
  