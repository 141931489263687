.scrollable-container {
    height: 100%; /* 设置固定高度 */
    border: 1px solid #ffffff; /* 可选：边框 */
    padding: 1px; /* 内边距 */
    text-align: left;
}

.scrollable-content {
    /* 可以根据需要添加更多样式 */
    height: 100%;
    overflow-y: auto; /* 超出内容时显示垂直滚动条 */
    background-color: transparent;
}

.tab-button {
    background: transparent;           /* 透明背景 */
    border: none;              /* 无边框 */
    color: #000;            /* 继承父元素的字体颜色 */
    cursor: pointer;           /* 鼠标指针样式 */
    padding: 0px 1px;                /* 内边距 */
    font-family		: 'Microsoft Yahei', 'Noto Sans CJK KR', '微软雅黑', 'applegothic', 'Sans-serif';
    font-size: 14px;
    margin-top: 20px;
}

.tab-button:hover {
  background: transparent;           /* 透明背景 */
    color: #FF4000;               /* 悬停时颜色 */
}

.tab-button:focus {
    outline: none;             /* 去掉焦点时的边框 */
}


  .tab-button.active {
    background: transparent;
    color: #FF4000;
    text-decoration: underline; /* 下划线 */
    text-underline-offset: 15px; /* 下划线与文本之间的距离 */
  }
  
  .cur {
    font-weight: bold;
  }