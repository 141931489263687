.frame {
  background-color: #f5f5f5;
  display: flex;
  width: 100%;
  height: 450px;
}


.frame .overlap-group {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-left: 25px;
  margin-right: 25px;
  height: 300px;
  width: 105%; /* 使用100%宽度，自动适应父容器 */
  padding: 15px; /* 内边距替代绝对定位 */
}

.frame .group {
  display: flex; /* 使用 flex 布局 */
  align-items: flex-start; /* 垂直对齐到顶部 */
  margin-bottom: 15px; /* SKU项之间的间距 */
}

.frame .checkbox {
  width: 20px; /* 设置复选框的宽度 */
  height: 20px; /* 设置复选框的高度 */
  margin-right: 15px; /* 复选框和图片之间的间距 */
}

.frame .img {
  width: 60px; /* 控制图片宽度 */
  height: 60px; /* 控制图片高度 */
  object-fit: cover; /* 保持图片比例 */
  margin-right: 15px; /* 图片和文本之间的间距 */
}

.frame .details {
  display: flex; /* 使用 flex 布局以便纵向排列属性和代码 */
  flex-direction: column; /* 设置纵向排列 */
}

.frame .m {
  margin-top: 5px; /* 属性文本与 SKU 代码之间的间距 */
  color: #2c2c2c; /* 字体颜色 */
  font-family: "Inter-Regular", Helvetica; /* 字体样式 */
  font-size: 14px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
}

.frame .text-wrapper {
  margin-top: 2px; /* 属性和代码之间的间距 */
  color: #2c2c2c; /* 字体颜色 */
  font-family: "Inter-Regular", Helvetica; /* 字体样式 */
  font-size: 14px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
}


.frame .overlap {
  background-color: #000000;
  border-radius: 30px;
  height: 30px;
  width: 140px;
  display: flex; /* 使用 flexbox 使按钮文本居中 */
  justify-content: center;
  align-items: center;
  margin: 20px auto 0; /* 上边距与水平居中 */
  margin-bottom: 15px;
  margin-right: 40px;
}

.frame .text-wrapper-2 {
  color: #ffffff;
  background-color: #000000;
  border: none;
  font-family: "Noto Sans CJK KR-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  text-align: center; /* 文本居中 */
}

.frame .text-wrapper-3 {
  color: #050505;
  font-family: "Noto Sans CJK KR-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  text-align: center; /* 文本居中 */
  margin-top: 10px;
  margin-bottom: 10px;
}
