.custom-modal .ant-modal-content {
  border-radius: 0px; /* 设置边角圆度 */
}


.filter-button {
  margin-right: 0px; /* 设置右侧间距 */
  font-family		: 'Microsoft Yahei', 'Noto Sans CJK KR', '微软雅黑', 'applegothic', 'Sans-serif';
    background: none;           /* 透明背景 */
    border: none;              /* 无边框 */
    color: inherit;            /* 继承父元素的字体颜色 */
    cursor: pointer;           /* 鼠标指针样式 */
    padding: 0px;                /* 内边距 */
    font-size: 14px;             /* 继承字体样式 */
    width: 30px;
}

.filter-button:hover {
    color: #FF4000;               /* 悬停时颜色 */
}

.filter-button:focus {
    outline: none;             /* 去掉焦点时的边框 */
}


  .filter-button.active {
    background-color: white;
    color: #FF4000;
    text-decoration: underline; /* 下划线 */
    text-underline-offset: 9px; /* 下划线与文本之间的距离 */
  }
  
  .cur {
    font-weight: bold;
  }