/* 
.myColorSet {
  sendbird-light-primary-500: #FF4000;
  sendbird-light-primary-400: #d64718;
  sendbird-light-primary-300: #aa583d;
  sendbird-light-primary-200: #6d5046;
  sendbird-light-primary-100: #EBEBEB;
};
 */
 .App {
  background: #fff;
  font-family: sans-serif;
}